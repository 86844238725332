import { default as indexbdAJntk19XMeta } from "/opt/buildhome/repo/pages/about/index.vue?macro=true";
import { default as _91name_93ixMsnUoqPkMeta } from "/opt/buildhome/repo/pages/articles/[name].vue?macro=true";
import { default as indexN5aA9NpkoaMeta } from "/opt/buildhome/repo/pages/articles/index.vue?macro=true";
import { default as _91name_93q4yXSzBqhOMeta } from "/opt/buildhome/repo/pages/catalog/[group]/[name].vue?macro=true";
import { default as indexQi5vO4j4zlMeta } from "/opt/buildhome/repo/pages/catalog/[group]/index.vue?macro=true";
import { default as indexbIBP9ziIqeMeta } from "/opt/buildhome/repo/pages/catalog/index.vue?macro=true";
import { default as indexOS1dovqTnaMeta } from "/opt/buildhome/repo/pages/conditioning/air-conditioning-maintenance/index.vue?macro=true";
import { default as index0FJaYiE8haMeta } from "/opt/buildhome/repo/pages/conditioning/index.vue?macro=true";
import { default as indexqghWDae5SGMeta } from "/opt/buildhome/repo/pages/conditioning/installing-air-conditioner/index.vue?macro=true";
import { default as indexR1u3jBLcbrMeta } from "/opt/buildhome/repo/pages/conditioning/repair-air-conditioner/index.vue?macro=true";
import { default as indexFusY45saolMeta } from "/opt/buildhome/repo/pages/contacts/index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as indexNSpBtSUQg6Meta } from "/opt/buildhome/repo/pages/ventilation/index.vue?macro=true";
import { default as indexkQQdhiYTFoMeta } from "/opt/buildhome/repo/pages/ventilation/installation-ventilation/index.vue?macro=true";
import { default as indexRHgayFseTUMeta } from "/opt/buildhome/repo/pages/ventilation/repair-ventilation/index.vue?macro=true";
import { default as indexHpD8E7lh7SMeta } from "/opt/buildhome/repo/pages/ventilation/ventilation-maintenance/index.vue?macro=true";
export default [
  {
    name: indexbdAJntk19XMeta?.name ?? "about",
    path: indexbdAJntk19XMeta?.path ?? "/about",
    meta: indexbdAJntk19XMeta || {},
    alias: indexbdAJntk19XMeta?.alias || [],
    redirect: indexbdAJntk19XMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93ixMsnUoqPkMeta?.name ?? "articles-name",
    path: _91name_93ixMsnUoqPkMeta?.path ?? "/articles/:name()",
    meta: _91name_93ixMsnUoqPkMeta || {},
    alias: _91name_93ixMsnUoqPkMeta?.alias || [],
    redirect: _91name_93ixMsnUoqPkMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/articles/[name].vue").then(m => m.default || m)
  },
  {
    name: indexN5aA9NpkoaMeta?.name ?? "articles",
    path: indexN5aA9NpkoaMeta?.path ?? "/articles",
    meta: indexN5aA9NpkoaMeta || {},
    alias: indexN5aA9NpkoaMeta?.alias || [],
    redirect: indexN5aA9NpkoaMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93q4yXSzBqhOMeta?.name ?? "catalog-group-name",
    path: _91name_93q4yXSzBqhOMeta?.path ?? "/catalog/:group()/:name()",
    meta: _91name_93q4yXSzBqhOMeta || {},
    alias: _91name_93q4yXSzBqhOMeta?.alias || [],
    redirect: _91name_93q4yXSzBqhOMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/catalog/[group]/[name].vue").then(m => m.default || m)
  },
  {
    name: indexQi5vO4j4zlMeta?.name ?? "catalog-group",
    path: indexQi5vO4j4zlMeta?.path ?? "/catalog/:group()",
    meta: indexQi5vO4j4zlMeta || {},
    alias: indexQi5vO4j4zlMeta?.alias || [],
    redirect: indexQi5vO4j4zlMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/catalog/[group]/index.vue").then(m => m.default || m)
  },
  {
    name: indexbIBP9ziIqeMeta?.name ?? "catalog",
    path: indexbIBP9ziIqeMeta?.path ?? "/catalog",
    meta: indexbIBP9ziIqeMeta || {},
    alias: indexbIBP9ziIqeMeta?.alias || [],
    redirect: indexbIBP9ziIqeMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: indexOS1dovqTnaMeta?.name ?? "conditioning-air-conditioning-maintenance",
    path: indexOS1dovqTnaMeta?.path ?? "/conditioning/air-conditioning-maintenance",
    meta: indexOS1dovqTnaMeta || {},
    alias: indexOS1dovqTnaMeta?.alias || [],
    redirect: indexOS1dovqTnaMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/conditioning/air-conditioning-maintenance/index.vue").then(m => m.default || m)
  },
  {
    name: index0FJaYiE8haMeta?.name ?? "conditioning",
    path: index0FJaYiE8haMeta?.path ?? "/conditioning",
    meta: index0FJaYiE8haMeta || {},
    alias: index0FJaYiE8haMeta?.alias || [],
    redirect: index0FJaYiE8haMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/conditioning/index.vue").then(m => m.default || m)
  },
  {
    name: indexqghWDae5SGMeta?.name ?? "conditioning-installing-air-conditioner",
    path: indexqghWDae5SGMeta?.path ?? "/conditioning/installing-air-conditioner",
    meta: indexqghWDae5SGMeta || {},
    alias: indexqghWDae5SGMeta?.alias || [],
    redirect: indexqghWDae5SGMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/conditioning/installing-air-conditioner/index.vue").then(m => m.default || m)
  },
  {
    name: indexR1u3jBLcbrMeta?.name ?? "conditioning-repair-air-conditioner",
    path: indexR1u3jBLcbrMeta?.path ?? "/conditioning/repair-air-conditioner",
    meta: indexR1u3jBLcbrMeta || {},
    alias: indexR1u3jBLcbrMeta?.alias || [],
    redirect: indexR1u3jBLcbrMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/conditioning/repair-air-conditioner/index.vue").then(m => m.default || m)
  },
  {
    name: indexFusY45saolMeta?.name ?? "contacts",
    path: indexFusY45saolMeta?.path ?? "/contacts",
    meta: indexFusY45saolMeta || {},
    alias: indexFusY45saolMeta?.alias || [],
    redirect: indexFusY45saolMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexNSpBtSUQg6Meta?.name ?? "ventilation",
    path: indexNSpBtSUQg6Meta?.path ?? "/ventilation",
    meta: indexNSpBtSUQg6Meta || {},
    alias: indexNSpBtSUQg6Meta?.alias || [],
    redirect: indexNSpBtSUQg6Meta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/ventilation/index.vue").then(m => m.default || m)
  },
  {
    name: indexkQQdhiYTFoMeta?.name ?? "ventilation-installation-ventilation",
    path: indexkQQdhiYTFoMeta?.path ?? "/ventilation/installation-ventilation",
    meta: indexkQQdhiYTFoMeta || {},
    alias: indexkQQdhiYTFoMeta?.alias || [],
    redirect: indexkQQdhiYTFoMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/ventilation/installation-ventilation/index.vue").then(m => m.default || m)
  },
  {
    name: indexRHgayFseTUMeta?.name ?? "ventilation-repair-ventilation",
    path: indexRHgayFseTUMeta?.path ?? "/ventilation/repair-ventilation",
    meta: indexRHgayFseTUMeta || {},
    alias: indexRHgayFseTUMeta?.alias || [],
    redirect: indexRHgayFseTUMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/ventilation/repair-ventilation/index.vue").then(m => m.default || m)
  },
  {
    name: indexHpD8E7lh7SMeta?.name ?? "ventilation-ventilation-maintenance",
    path: indexHpD8E7lh7SMeta?.path ?? "/ventilation/ventilation-maintenance",
    meta: indexHpD8E7lh7SMeta || {},
    alias: indexHpD8E7lh7SMeta?.alias || [],
    redirect: indexHpD8E7lh7SMeta?.redirect || undefined,
    component: () => import("/opt/buildhome/repo/pages/ventilation/ventilation-maintenance/index.vue").then(m => m.default || m)
  }
]